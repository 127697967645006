import request from './base/request';
import store from '@/store';

export const getListUserApi = (params) => {
  return request
    .get('/', { params })
    .then((res) => {
      const data = res.data;
      store.dispatch('users/actionGetUserManage', data, { root: true });

      return res;
    })
    .catch((err) => {
      console.error('err', err);
      throw err;
    });
};
export const createUserApi = (data) => {
  return request
    .post('/', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const updateUserApi = (data) => {
  return request
    .put('/', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const verifyEmail = (data) => {
  return request
    .post('/send-verify-email', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const createUserGroupApi = (data) => {
  return request
    .post('/user-group', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateUserGroupApi = (data) => {
  return request
    .put('/user-group', data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const getUserGroupDetailApi = (id) => {
  return request
    .get(`/user-group/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteUserGroupDetailApi = (id) => {
  return request
    .delete(`/user-group/${id}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};


export const getUsersGroupApi = () => {
  return request
    .get('/user-group')
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
